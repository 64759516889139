import {
  LIST_ROUTE_NAME,
  LIST_ROUTE_PATH,
  MAIN_ROUTE_LABEL,
  MAIN_ROUTE_NAME,
  MAIN_ROUTE_PATH,
} from './constants';

export const MainRoute = {
  name: MAIN_ROUTE_NAME,
  path: MAIN_ROUTE_PATH,
};

export const ListRoute = {
  name: LIST_ROUTE_NAME,
  path: LIST_ROUTE_PATH,
};

export const routes = [
  {
    ...MainRoute,
    meta: {
      mainRouteName: MAIN_ROUTE_NAME,
      label: MAIN_ROUTE_LABEL,
    },
    redirect: { name: LIST_ROUTE_NAME },
    children: [
      {
        ...ListRoute,
        components: {
          default: () => import('./views/ListModels.vue'),
        },
      },
    ],
  },
];
