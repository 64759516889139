import { createRouter, createWebHistory } from 'vue-router';
import { guardForEntryPage, guardForCallbackPage } from '@aleph-alpha/lib-auth';
import { User } from 'index';
import { auth } from '@/plugins/auth';
import http from '../http';

import { routes as Hardware } from '@/modules/Hardware/routes';
import { routes as Credentials } from '@/modules/Credentials/routes';
import { routes as Users, MainRoute as UsersMainRoute } from '@/modules/Users/routes';
import { routes as Models } from '@/modules/Models/routes';
import { useUserProfile } from '@/stores/useUserProfile';

const addBearerToken = (type, token) =>
  http.updateConfig({
    headers: {
      Authorization: `${type} ${token}`,
    },
  });

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      guardForEntryPage(auth, (user: User) => {
        addBearerToken(user.token_type, user.access_token);
        next({ name: UsersMainRoute.name });
      });
    },
  },
  {
    path: '/callback',
    name: 'LoginCallback',
    beforeEnter: (to, from, next) => {
      guardForCallbackPage(auth, () => next({ name: UsersMainRoute.name }));
    },
  },
  {
    path: '/app',
    name: 'App',
    components: {
      layout: () => import('@/layouts/AppLayout.vue'),
    },
    children: [...Hardware, ...Models, ...Users, ...Credentials],
    beforeEnter: (to, from, next) => {
      guardForEntryPage(auth, (user: User) => {
        const profile = useUserProfile();
        profile.setProfile(user);
        addBearerToken(user.token_type, user.access_token);
        next();
      });
    },
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
