import { onBeforeMount, watch } from 'vue';
import { useStorage, useNavigatorLanguage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

export const useStoredLocale = () => {
  const availableLocales = new Set(['en', 'de']);
  const { language } = useNavigatorLanguage();
  const { locale: currentLocale, fallbackLocale } = useI18n();

  const checkIfLocaleIsValid = (language: string): string => {
    if (availableLocales.has(language)) {
      return language;
    }

    return fallbackLocale.value;
  };

  const localeInStorage = useStorage(
    'locale',
    new Intl.Locale(checkIfLocaleIsValid(language.value as string)).language
  );

  watch(
    () => currentLocale.value,
    (newLocale) => {
      localeInStorage.value = newLocale;
    }
  );

  onBeforeMount(() => {
    if (localeInStorage.value) currentLocale.value = localeInStorage.value;
  });

  const onChangeLocale = (newLocale: string) => {
    currentLocale.value = checkIfLocaleIsValid(newLocale);
  };

  return {
    fallbackLocale,
    availableLocales,
    currentLocale,
    localeInStorage,
    onChangeLocale,
  };
};
