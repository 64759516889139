import { reactive } from 'vue';
import { defineStore } from 'pinia';

// import { auth } from '@/plugins/auth';
import { IdTokenClaims } from 'oidc-client-ts';

export const useUserProfile = defineStore('Profile', () => {
  const profile: Partial<IdTokenClaims> = reactive({
    sub: '',
    email: '',
    given_name: '',
    last_name: '',
    roles: [],
  });

  const setProfile = async (userData) => {
    profile.sub = userData.profile.sub;
    profile.email = userData.profile.email;
    profile.given_name = userData.profile.given_name;
    profile.last_name = userData.profile.family_name;
  };

  return {
    profile,
    setProfile,
  };
});
