import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';

export default createI18n({
  legacy: false,
  messages: {
    en,
    de,
  },
  locale: 'en',
  // something vue-i18n options here ...
});
