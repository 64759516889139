import { COMMON_TABLE_HEADER_TRANSLATION_PATH } from '@/constants/tables';

import { TableHeader } from '@/types/tables';
import { HardwareTableHeader } from '@/modules/Hardware/types';

export const MAIN_ROUTE_PATH = 'hardware';
export const MAIN_ROUTE_NAME = 'HARDWARE';
export const MAIN_ROUTE_LABEL = 'Hardware';

export const LIST_ROUTE_PATH = 'list';
export const LIST_ROUTE_NAME = `${MAIN_ROUTE_NAME}_LIST`;
export const LIST_ROUTE_LABEL = 'List Hardware';

export const HARDWARE_TABLE_TRANSLATION_PATH = 'hardware.list.table';
const HARDWARE_TABLE_TRANSLATION_HEADER_PATH = `${HARDWARE_TABLE_TRANSLATION_PATH}.headers`;

export const TABLE_HEADERS: TableHeader<HardwareTableHeader> = [
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.status`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.name`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.cores`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.memory`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.gpu_amount`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.gpu_type`,
  },
  {
    id: crypto.randomUUID(),
    label: `${HARDWARE_TABLE_TRANSLATION_HEADER_PATH}.gpu_memory`,
  },
];
