import { createApp } from 'vue';
import { createPinia } from 'pinia';

import DS from '@aleph-alpha/ds-components-vue';

import '@unocss/reset/tailwind.css';
import 'uno.css';
import 'virtual:uno.css';
import App from './App.vue';

import router from '@/plugins/router';
import localization from '@/plugins/localization';
import './plugins/auth';

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(DS);
app.use(router);
app.use(localization);

app.mount('#app');
