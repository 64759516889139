import { COMMON_TABLE_HEADER_TRANSLATION_PATH } from '@/constants/tables';

export const MAIN_ROUTE_PATH = 'credentials';
export const MAIN_ROUTE_NAME = 'CREDENTIAL';
export const MAIN_ROUTE_LABEL = 'Credentials';

export const LIST_ROUTE_PATH = 'list';
export const LIST_ROUTE_NAME = `${MAIN_ROUTE_NAME}_LIST`;
export const LIST_ROUTE_LABEL = 'List Credentials';

export const DETAIL_ROUTE_PATH = ':id/detail';
export const DETAIL_ROUTE_NAME = `${MAIN_ROUTE_NAME}_DETAIL`;
export const DETAIL_ROUTE_LABEL = 'Detail Credential';

export const LIST_HEADER = [
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.name`,
  },
  {
    id: crypto.randomUUID(),
    label: 'global.created-at',
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.role`,
  },
  {
    id: crypto.randomUUID(),
    label: '',
  },
];
