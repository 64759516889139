import { COMMON_TABLE_HEADER_TRANSLATION_PATH } from '@/constants/tables';

export const MAIN_ROUTE_PATH = 'users';
export const MAIN_ROUTE_NAME = 'USER';
export const MAIN_ROUTE_LABEL = 'Users';

export const LIST_ROUTE_PATH = 'list';
export const LIST_ROUTE_NAME = 'USER_LIST';
export const LIST_ROUTE_LABEL = 'List Users';

export const CREATE_ROUTE_PATH = 'new';
export const CREATE_ROUTE_NAME = 'USER_CREATE';
export const CREATE_ROUTE_LABEL = 'Create User';

export const EDIT_ROUTE_PATH = ':id';
export const EDIT_ROUTE_NAME = 'USER_EDIT';
export const EDIT_ROUTE_LABEL = 'Edit User';

const COMMON_TRANSLATION_PATH = 'user.list.table.header';

export const LIST_HEADER = [
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.id`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.name`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TRANSLATION_PATH}.last-name`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TRANSLATION_PATH}.email`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TRANSLATION_PATH}.creation-date`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TRANSLATION_PATH}.role`,
  },
  {
    id: crypto.randomUUID(),
    label: `${COMMON_TABLE_HEADER_TRANSLATION_PATH}.status`,
  },
  {
    id: crypto.randomUUID(),
    label: '',
  },
];
